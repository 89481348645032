<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            class="kunde-avatar"
            :src="
              userData.User.avatar
                ? $dbBaseUrl + userData.User.avatar.formats.thumbnail.url
                : ''
            "
            :text="`${userData.Firma.charAt()}`"
            :variant="`light-secondary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.Firma }}
              </h4>
              <a :href="`mailto:${userData.User.email}`"
                ><span class="card-text">{{ userData.User.email }}</span></a
              >
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'kunde-bearbeiten',
                  params: { id: userData.id },
                }"
                variant="primary"
              >
                Bearbeiten
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                v-b-modal:[`modal-delete-customer-${userData.id}`]
              >
                Löschen
              </b-button>
              <delete-customer-modal :userData="userData" />
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.User.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Bewertung</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="rating[1][userData.Bewertung]">
                {{ userData.Bewertung }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefon</span>
            </th>
            <td>
              <a :href="`tel:${userData.Kontakt.Telefon}`">{{
                userData.Kontakt.Telefon
              }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="SmartphoneIcon" class="mr-75" />
              <span class="font-weight-bold">Mobil</span>
            </th>
            <td class="pb-50">
              <a :href="`tel:${userData.Kontakt.Mobil}`">{{
                userData.Kontakt.Mobil
              }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FileTextIcon" class="mr-75" />
              <span class="font-weight-bold">Fax</span>
            </th>
            <td class="pb-50">
              <a :href="`tel:${userData.Kontakt.Fax}`">{{
                userData.Kontakt.Fax
              }}</a>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  VBModal,
} from 'bootstrap-vue';
import DeleteCustomerModal from '@/layouts/components/modals/DeleteCustomerModal.vue';
// import useUsersList from '../users-list/useUsersList';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    VBModal,
    DeleteCustomerModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rating: [
        {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
        },
        {
          1: 'success',
          2: 'primary',
          3: 'info',
          4: 'secondary',
          5: 'warning',
          6: 'danger',
        },
      ],
    };
  },
};
</script>

<style>
.kunde-avatar .b-avatar-img img {
  object-fit: contain;
}
</style>
